import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { TableData } from '../../../core/interfaces/table-data';
import { CommonModule } from '@angular/common';
import { sortTableData, formatTableData, sortTableDataByColumn } from '../../../core/utils/table-data.utils';
import { ReactiveFormsModule } from '@angular/forms';
import { formatDate, formatDateString } from '../../../core/utils/mapping.utils';

@Component({
  selector: 'app-table',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnChanges {
  @Input() data: TableData[] = [];
  @Input() columnsExcluded: string[] = [];
  @Input() isTableReady: boolean = true;  
  @Output() rowClicked: EventEmitter<TableData> = new EventEmitter<TableData>();

  columnTranslataions: ColumnTranslations = {
    id: 'ID',
    name: 'Nombre',
    surname: 'Apellido',
    email: 'Correo',
    phone: 'Teléfono',
    role: 'Rol',
    address: 'Dirección',
    addres: 'Dirección',
    cuit: 'CUIT',
    description: 'Descripción',
    category: 'Categoría',
    quantity: 'Cantidad',
    expirationDate: 'Fecha de vencimiento',
    creationDate: 'Fecha de creación',
    lastModificationDate: 'Última modificación',
    admissionDate: 'Fecha de admisión',
    unit: 'Unidad',
    price: 'Precio',
    cost: 'Precio',
    expiration: 'Fecha de Vencimiento',
    type: 'Tipo',
    envenDate: 'Fecha',
    title: 'Evento',
  };

  currentPage: number = 1;
  itemsPerPage: number = 10;

  noDataMessage: string = 'No hay elementos a mostrar';

  sortStates: { [key: string]: 'up' | 'down' | '' } = {};
  activeColumn: string | null = null;

  ngOnInit(){
    this.columnsExcluded.push('state');
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data']) {
      this.data = formatTableData(this.data);
      this.data = sortTableData(this.data);
    }
  }

  getColumns(): string[] {
    if (this.data.length === 0) {
      return [];
    }
    return Object.keys(this.data[0]).filter(column => !this.columnsExcluded.includes(column));
  }

  get paginatedData(): TableData[] {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    return this.data.slice(startIndex, endIndex);
  }

  get totalPages(): number {
    return Math.ceil(this.data.length / this.itemsPerPage);
  }

  setPage(page: number) {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
    }
  }

  getPageRange(): number[] {
    const nearbyPages = [];
    const maxNearby = 1;

    let startPage = Math.max(1, this.currentPage - maxNearby);
    let endPage = Math.min(this.totalPages, this.currentPage + maxNearby);

    if (this.currentPage <= maxNearby + 1) {
      endPage = Math.min(maxNearby * 2 + 1, this.totalPages);
    }
    if (this.currentPage >= this.totalPages - maxNearby) {
      startPage = Math.max(this.totalPages - maxNearby * 2, 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      nearbyPages.push(i);
    }
    return nearbyPages;
  }

  onRowClick(item: TableData) {
    this.rowClicked.emit(item);
  }

  translateColumn(column: string): string {
    return this.columnTranslataions[column] || column;
  }

  onHeaderClick(column: string): void {
    if (this.activeColumn === column) {
      this.sortStates[column] = this.sortStates[column] === 'up' ? 'down' : 'up';
      this.data = sortTableDataByColumn(this.data, column, this.sortStates[column]);
    } else {
      this.sortStates[this.activeColumn || ''] = '';
      this.sortStates[column] = 'up';
      this.activeColumn = column;
    }
  }

  getSortIcon(column: string): string {
    if (this.sortStates[column] === 'up') {
      return 'bi bi-caret-up-fill';
    } else if (this.sortStates[column] === 'down') {
      return 'bi bi-caret-down-fill';
    }
    return '';
  }
  
  
  getAgeDifference(expirationDate: any): number {
    if(expirationDate === null || expirationDate === undefined) return 999;
    const currentYear = new Date().getFullYear();
    console.log("Current: ",expirationDate);
    const aux = formatDateString(expirationDate);
    console.log("Aux: ",aux);
    const expirationYear = new Date(aux).getFullYear();
    console.log("Gap: ",currentYear, expirationYear);
    return expirationYear - currentYear;
  }

}

interface ColumnTranslations {
  [key: string]: string;
}
